// middleware/auth.ts
import { defineNuxtRouteMiddleware, navigateTo } from 'nuxt/app';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export default defineNuxtRouteMiddleware((to, from) => {
  const userData = cookies.get('authStore');

  if (userData) {
    try {
    //   console.log('Raw auth data from cookies:', userData);
      // Check if the data is already an object or needs parsing
      const parsedData = typeof userData === 'string' ? JSON.parse(userData) : userData;
    //   console.log('Parsed auth data from cookies:', parsedData);

      if (!parsedData.partnerName) {
        // console.log('Partner name is empty, redirecting to /');
        // return navigateTo('/');
      }

    //   console.log('Middleware check passed, proceeding to route:', to.path);
    } catch (error) {
    //   console.error('Failed to parse auth data from cookies:', error);
    //   return navigateTo('/');
    }
  } else {
    console.log('No auth data in cookies, redirecting to /');
    // return navigateTo('/');
  }
});
